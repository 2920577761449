<template>
  <div>
    <div class="breadcrumb">
      <div style="text-align: right">
        <el-button type="primary" @click="getSubmit(1)">存为草稿</el-button>
        <el-button type="primary" @click="getSubmit(2)">提交</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
      </div>
    </div>
    <!-- <template>
      <v-region :town="true" @values="selectLogs" v-model="province"></v-region>
    </template> -->
    <div class="detailsWrapper">
      <div class="btnWrapper">
        <el-button type="text" size="mini" @click="allAn">全部展开</el-button>
        <el-button type="text" size="mini" @click="packUp">全部收起</el-button>
      </div>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="停车场信息" name="1">
          <info-table :tableData="parkInfo" :num="3">
            <template v-slot:parkFilingName>
              <el-input
                class="check"
                placeholder="请输入停车场名称"
                size="mini"
                v-model="formData.parkFilingName"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:parkFilingCode>
              <el-input
                class="check"
                placeholder="请输入停车场编号"
                size="mini"
                v-model="formData.parkFilingCode"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:parkFilingType>
              <el-select
                v-model="formData.parkFilingType"
                size="mini"
                placeholder="请选择停车场类型"
              >
                <el-option
                  v-for="item in parkFilingTypeList"
                  :key="item.code"
                  :label="item.desc"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </template>
            <template v-slot:city>
              <el-select
                v-model="formData.city1"
                size="mini"
                placeholder="请选择城市"
                @change="handleCity"
              >
                <el-option
                  v-for="item in cityList"
                  :key="item.cityId"
                  :label="item.name"
                  :value="item.cityId"
                >
                </el-option>
              </el-select>
            </template>
            <template v-slot:area>
              <el-select
                v-model="formData.area1"
                size="mini"
                placeholder="请选择所属行政区"
                @change="handleArea"
              >
                <el-option
                  v-for="item in areaList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </template>
            <template v-slot:street>
              <el-select
                v-model="formData.street1"
                size="mini"
                placeholder="请选择所属街道"
                @change="handleStreet"
              >
                <el-option
                  v-for="item in streetList"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </template>
            <template v-slot:policeId>
              <el-select v-model="formData.policeId" size="mini" placeholder="请选择所属派出所">
                <el-option
                  v-for="item in policeIdList"
                  :key="item.parkFilingDictionaryId"
                  :label="item.dictionaryContent"
                  :value="item.parkFilingDictionaryId"
                >
                </el-option>
              </el-select>
            </template>
            <template v-slot:businessCenterId>
              <el-select
                v-model="formData.businessCenterId"
                size="mini"
                placeholder="请选择所属商圈"
              >
                <el-option
                  v-for="item in businessCenterIdList"
                  :key="item.businessCenterId"
                  :label="item.businessCenterName"
                  :value="item.businessCenterId"
                >
                </el-option>
              </el-select>
            </template>
            <template v-slot:trafficPoliceBranchId>
              <el-select
                v-model="formData.trafficPoliceBranchId"
                size="mini"
                placeholder="请选择所属交警分局"
              >
                <el-option
                  v-for="item in trafficPoliceBranchIdList"
                  :key="item.parkFilingDictionaryId"
                  :label="item.dictionaryContent"
                  :value="item.parkFilingDictionaryId"
                >
                </el-option>
              </el-select>
            </template>
            <template v-slot:address>
              <el-input
                class="check"
                placeholder="请输入详细地址"
                size="mini"
                v-model="formData.address"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:parkFilingArea>
              <el-input
                class="check"
                placeholder="请输入停车场面积(㎡）"
                size="mini"
                v-model="formData.parkFilingArea"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:fourToRange>
              <el-input
                class="check"
                placeholder="请输入停车场四至范围"
                size="mini"
                v-model="formData.fourToRange"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:position>
              <el-select v-model="formData.position" size="mini" placeholder="请选择环路位置">
                <el-option
                  v-for="item in parkFilingPosition"
                  :key="item.code"
                  :label="item.desc"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </template>
            <template v-slot:facilitiesLand>
              <el-select
                v-model="formData.facilitiesLand"
                size="mini"
                placeholder="请选择停车设施用地"
              >
                <el-option
                  v-for="item in facilitiesLandList"
                  :key="item.code"
                  :label="item.desc"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </template>
            <template v-slot:parkId>
              <el-select v-model="formData.parkId" size="mini" placeholder="请选择关联平台车场">
                <el-option
                  v-for="item in parkIdList"
                  :key="item.parkId"
                  :label="item.parkName"
                  :value="item.parkId"
                >
                </el-option>
              </el-select>
            </template>
            <template v-slot:parkStatus>
              <el-select v-model="formData.parkStatus" size="mini" placeholder="请选择停车场状态">
                <el-option
                  v-for="item in parkStatusList"
                  v-show="item.code === 1 || item.code === 2"
                  :key="item.code"
                  :label="item.desc"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </template>
            <template v-slot:occupationType>
              <el-select v-model="formData.occupationType" size="mini" placeholder="请选择占道类型">
                <el-option
                  v-for="item in occupationTypeList"
                  :key="item.code"
                  :label="item.desc"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </template>
            <template v-slot:parkingMethod>
              <el-select v-model="formData.parkingMethod" size="mini" placeholder="请选择停车方式">
                <el-option
                  v-for="item in parkingMethodList"
                  :key="item.code"
                  :label="item.desc"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </template>
            <template v-slot:personInCharge>
              <el-input
                class="check"
                placeholder="请输入停车场负责人"
                size="mini"
                v-model="formData.personInCharge"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:personInChargePhone>
              <el-input
                class="check"
                placeholder="请输入负责人联系方式"
                size="mini"
                v-model="formData.personInChargePhone"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:telephone>
              <el-input
                class="check"
                placeholder="请输入固定电话"
                size="mini"
                v-model="formData.telephone"
                maxlength="100"
              ></el-input>
            </template>
          </info-table>
        </el-collapse-item>
        <el-collapse-item title="泊位信息" name="2">
          <info-table :tableData="berthInfo" :num="3">
            <template v-slot:totalBerthNumber>
              <el-input
                class="check"
                placeholder="请输入总泊位数"
                size="mini"
                v-model="formData.totalBerthNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:dedicatedBerthNumber>
              <el-input
                class="check"
                placeholder="请输入专用泊位数"
                size="mini"
                v-model="formData.dedicatedBerthNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:operationalBerthNumber>
              <el-input
                class="check"
                placeholder="请输入经营性泊位数"
                size="mini"
                v-model="formData.operationalBerthNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:noOperationalBerthNumber>
              <el-input
                class="check"
                placeholder="请输入非经营性泊位数"
                size="mini"
                v-model="formData.noOperationalBerthNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:planningBerthNumber>
              <el-input
                class="check"
                placeholder="请输入规划泊位数"
                size="mini"
                v-model="formData.planningBerthNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:sharedBerthNumber>
              <el-input
                class="check"
                placeholder="请输入共享泊位数"
                size="mini"
                v-model="formData.sharedBerthNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:longLeaseBerthNumber>
              <el-input
                class="check"
                placeholder="请输入长租泊位数"
                size="mini"
                v-model="formData.longLeaseBerthNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:elecVehiclesBerthNumber>
              <el-input
                class="check"
                placeholder="请输入电动汽车专用泊位数"
                size="mini"
                v-model="formData.elecVehiclesBerthNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:barrierFreeBerthNumber>
              <el-input
                class="check"
                placeholder="请输入无障碍泊位数"
                size="mini"
                v-model="formData.barrierFreeBerthNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:largeCarBerthNumber>
              <el-input
                class="check"
                placeholder="请输入大型车泊位数"
                size="mini"
                v-model="formData.largeCarBerthNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:littleCarBerthNumber>
              <el-input
                class="check"
                placeholder="请输入小型车泊位数"
                size="mini"
                v-model="formData.littleCarBerthNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:groundBerthNumber>
              <el-input
                class="check"
                placeholder="请输入地面泊位数"
                size="mini"
                v-model="formData.groundBerthNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:undergroundBerthNumber>
              <el-input
                class="check"
                placeholder="请输入地下泊位数"
                size="mini"
                v-model="formData.undergroundBerthNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:floorTypeBerthNumber>
              <el-input
                class="check"
                placeholder="请输入楼层式泊位数"
                size="mini"
                v-model="formData.floorTypeBerthNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:mechanicalLiftBerthNumber>
              <el-input
                class="check"
                placeholder="请输入机械提升式泊位数"
                size="mini"
                v-model="formData.mechanicalLiftBerthNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:groundMechanicalBerthNumber>
              <el-input
                class="check"
                placeholder="请输入地上机械式泊位数"
                size="mini"
                v-model="formData.groundMechanicalBerthNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:underGroundMechanicalBerthNumber>
              <el-input
                class="check"
                placeholder="请输入地下机械式泊位数"
                size="mini"
                v-model="formData.underGroundMechanicalBerthNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:walkingStereoBerthNumber>
              <el-input
                class="check"
                placeholder="请输入自走式立体停车泊位数"
                size="mini"
                v-model="formData.walkingStereoBerthNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:airDefenseBerthNumber>
              <el-input
                class="check"
                placeholder="请输入人防车位数"
                size="mini"
                v-model="formData.airDefenseBerthNumber"
                maxlength="100"
              ></el-input>
            </template>
          </info-table>
        </el-collapse-item>
        <el-collapse-item title="出入口信息" name="3">
          <info-table :tableData="outInInfo" :num="3">
            <template v-slot:entryExitNumber>
              <el-input
                class="check"
                placeholder="请输入出入口总数"
                size="mini"
                v-model="formData.entryExitNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:entryNumber>
              <el-input
                class="check"
                placeholder="请输入入口数量"
                size="mini"
                v-model="formData.entryNumber"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:exitNumber>
              <el-input
                class="check"
                placeholder="请输入出口数量"
                size="mini"
                v-model="formData.exitNumber"
                maxlength="100"
              ></el-input>
            </template>
          </info-table>
          <div class="tableWrapper">
            <el-button class="btn" type="primary" size="mini" @click="handleEntry"
              >添加入口</el-button
            >
            <div class="content">
              <div class="box_del" v-for="(item, index) in entryTable" :key="index">
                <el-row>
                  <el-col :span="6">
                    <div class="grid-content bg-purple">
                      <label>入口{{ index + 1 }}编号</label>
                      <el-input
                        class="check"
                        placeholder="请输入入口编号"
                        size="mini"
                        v-model="item.entryCode"
                        maxlength="100"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="grid-content bg-purple">
                      <label>入口{{ index + 1 }}经度</label>
                      <el-input
                        class="check"
                        placeholder="请输入入口经度"
                        size="mini"
                        v-model="item.entryLongitude"
                        maxlength="100"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="grid-content bg-purple">
                      <label>入口{{ index + 1 }}纬度</label>
                      <el-input
                        class="check"
                        placeholder="请输入入口纬度"
                        size="mini"
                        v-model="item.entryLatitude"
                        maxlength="100"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="grid-content bg-purple">
                      <label>入口{{ index + 1 }}状态</label>
                      <el-select
                        v-model="item.entryStatus"
                        size="mini"
                        placeholder="请选择入口状态"
                      >
                        <el-option
                          v-for="item in entryStatusList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-col>
                </el-row>
                <el-button class="delbtn" type="primary" size="mini" @click="handleEntryDel(index)"
                  >删除
                </el-button>
              </div>
            </div>
          </div>
          <div class="tableWrapper">
            <el-button class="btn" type="primary" size="mini" @click="handleExit"
              >添加出口</el-button
            >
            <div class="content">
              <div class="box_del" v-for="(item, index) in exitTable" :key="index">
                <el-row>
                  <el-col :span="6">
                    <div class="grid-content bg-purple">
                      <label>出口{{ index + 1 }}编号</label>
                      <el-input
                        class="check"
                        placeholder="请输入出口编号"
                        size="mini"
                        v-model="item.entryCode"
                        maxlength="100"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="grid-content bg-purple">
                      <label>出口{{ index + 1 }}经度</label>
                      <el-input
                        class="check"
                        placeholder="请输入出口经度"
                        size="mini"
                        v-model="item.entryLongitude"
                        maxlength="100"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="grid-content bg-purple">
                      <label>出口{{ index + 1 }}纬度</label>
                      <el-input
                        class="check"
                        placeholder="请输入出口纬度"
                        size="mini"
                        v-model="item.entryLatitude"
                        maxlength="100"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="6">
                    <div class="grid-content bg-purple">
                      <label>出口{{ index + 1 }}状态</label>
                      <el-select
                        v-model="item.entryStatus"
                        size="mini"
                        placeholder="请选择出口状态"
                      >
                        <el-option
                          v-for="item in entryStatusList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-col>
                </el-row>
                <el-button class="delbtn" type="primary" size="mini" @click="handleExitDel(index)"
                  >删除
                </el-button>
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="收费信息" name="4">
          <info-table :tableData="chargeInfo" :num="3">
            <template v-slot:businessTime>
              <el-time-picker
                is-range
                size="mini"
                value-format="HH:mm:ss"
                v-model="businessTime"
                range-separator="至"
                start-placeholder="开始时间"
                @change="openTimeChange"
                end-placeholder="结束时间"
                placeholder="选择营业时间"
              >
              </el-time-picker>
            </template>
            <template v-slot:firstHourPrice>
              <el-input
                @input="
                  formData.firstHourPrice = formData.firstHourPrice.replace(/^(0+)|[^\d]+/g, '')
                "
                class="check"
                placeholder="请输入首小时价格"
                size="mini"
                v-model="formData.firstHourPrice"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:nofirstHourPrice>
              <el-input
                class="check"
                @input="
                  formData.nofirstHourPrice = formData.nofirstHourPrice.replace(/^(0+)|[^\d]+/g, '')
                "
                placeholder="请输入非首小时每小时单价"
                size="mini"
                v-model="formData.nofirstHourPrice"
                maxlength="100"
              ></el-input>
            </template>
          </info-table>
          <info-table
            :tableData="ruleInfo"
            :nameStyle="{ height: '150px', lineHeight: '150px' }"
            :valueStyle="{ height: '150px' }"
          >
            <template v-slot:rule>
              <el-input
                class="textArea"
                type="textarea"
                autosize
                placeholder="请输入计费规则"
                maxlength="500"
                v-model="formData.feeDesc"
              >
              </el-input>
            </template>
          </info-table>
        </el-collapse-item>
        <el-collapse-item title="员工信息" name="5">
          <div class="employ">
            <info-table
              :style="{ width: '383px' }"
              :tableData="employeeInfo"
              :nameStyle="{ width: '190px' }"
              :valueStyle="{ width: '190px' }"
            >
              <template v-slot:employeeNum>
                <el-input
                  readonly
                  class="check"
                  placeholder="请输入员工总数"
                  size="mini"
                  v-model="formData.employeeNum"
                  maxlength="100"
                ></el-input>
              </template>
            </info-table>
            <el-button class="export" type="primary" size="mini" @click="handleExport"
              >{{ $t('button.import') }}</el-button
            >
          </div>
          <el-table :data="tableData" style="width: 100% !important">
            <el-table-column
              align="center"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              v-for="item in tableCols"
              :key="item.prop"
              :formatter="item.formatter"
            >
              <template slot-scope="scope">
                <el-select
                  v-if="item.prop == 'gender'"
                  size="mini"
                  v-model="scope.row.gender"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in setList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-else-if="item.prop == 'status'"
                  size="mini"
                  v-model="scope.row.status"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in statusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <el-input
                  v-else
                  v-model="scope.row[item.prop]"
                  size="mini"
                  :placeholder="item.label"
                >
                </el-input>
              </template>
            </el-table-column>
          </el-table>
          <div class="addBtn">
            <el-button type="primary" size="mini" @click="handleAddTable">{{ $t('button.addto') }}</el-button>
          </div>
        </el-collapse-item>
        <el-collapse-item title="产权信息" name="6">
          <info-table :tableData="propertyInfo" :num="2">
            <template v-slot:operationId>
              <el-select v-model="formData.operationId" size="mini" placeholder="请选择经营企业">
                <el-option
                  v-for="item in companyList"
                  :key="item.operationId"
                  :label="item.operationName"
                  :value="item.operationId"
                >
                </el-option>
              </el-select>
            </template>
            <template v-slot:propertyRights>
              <el-select v-model="formData.propertyRights" size="mini" placeholder="请选择产权性质">
                <el-option
                  v-for="item in propertyRightsList"
                  :key="item.code"
                  :label="item.desc"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </template>
            <template v-slot:property>
              <el-input
                class="check"
                placeholder="请输入产权方"
                size="mini"
                v-model="formData.property"
                maxlength="100"
              ></el-input>
            </template>
            <template v-slot:leaseValidity>
              <!--              <el-date-picker size='mini' v-model="formData.leaseValidity" type="date"-->
              <!--                              placeholder="停车场租赁有效期">-->
              <!--              </el-date-picker>-->
              <el-date-picker
                v-model="formData.leaseValidity"
                size="mini"
                align="right"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="停车场租赁有效期"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </template>
          </info-table>
        </el-collapse-item>
        <el-collapse-item
          title="上传资料（支持.jpg,.png,.pdf文件上传,每类材料不得超过10张）"
          name="7"
        >
          <div class="grid-content bg-purple">
            <p>工商营业执照</p>
            <el-upload
              :on-remove="handleRemove"
              :on-exceed="handleExceed"
              :before-upload="beforeUpload"
              :on-success="handleSuccess"
              :on-change="handleEditChange"
              :limit="10"
              :file-list="fileList"
              name="image"
              :headers="uploadHeader"
              action="/acb/2.0/parkFiling/uploadImage"
              :data="uploadData"
              list-type="picture-card"
              :class="{ hide: hideUploadEdit }"
            >
              <i class="el-icon-plus" />
            </el-upload>
          </div>
          <div class="grid-content bg-purple">
            <p>土地使用权或产权证明</p>
            <el-upload
              :on-remove="handleRemove1"
              :on-exceed="handleExceed"
              :before-upload="beforeUpload"
              :on-success="handleSuccess1"
              :on-change="handleEditChange1"
              :limit="10"
              :file-list="fileList1"
              name="image"
              :headers="uploadHeader"
              action="/acb/2.0/parkFiling/uploadImage"
              :data="uploadData1"
              list-type="picture-card"
              :class="{ hide: hideUploadEdit1 }"
            >
              <i v-show="fileList1.length < 1" class="el-icon-plus" />
            </el-upload>
          </div>
          <div class="grid-content bg-purple">
            <p>泊位平面图或方位图</p>
            <el-upload
              :on-remove="handleRemove2"
              :on-exceed="handleExceed"
              :before-upload="beforeUpload"
              :on-success="handleSuccess2"
              :on-change="handleEditChange2"
              :limit="10"
              :file-list="fileList2"
              name="image"
              :headers="uploadHeader"
              action="/acb/2.0/parkFiling/uploadImage"
              :data="uploadData2"
              list-type="picture-card"
              :class="{ hide: hideUploadEdit2 }"
            >
              <i v-show="fileList2.length < 1" class="el-icon-plus" />
            </el-upload>
          </div>
          <div class="grid-content bg-purple">
            <p>竣工验收证明材料</p>
            <el-upload
              :on-remove="handleRemove3"
              :on-exceed="handleExceed"
              :before-upload="beforeUpload"
              :on-success="handleSuccess3"
              :on-change="handleEditChange3"
              :limit="10"
              :file-list="fileList3"
              name="image"
              :headers="uploadHeader"
              action="/acb/2.0/parkFiling/uploadImage"
              :data="uploadData3"
              list-type="picture-card"
              :class="{ hide: hideUploadEdit3 }"
            >
              <i v-show="fileList3.length < 1" class="el-icon-plus" />
            </el-upload>
          </div>
          <div class="grid-content bg-purple">
            <p>停车场照片</p>
            <el-upload
              :on-remove="handleRemove4"
              :on-exceed="handleExceed"
              :before-upload="beforeUpload"
              :on-success="handleSuccess4"
              :on-change="handleEditChange4"
              :limit="10"
              :file-list="fileList4"
              name="image"
              :headers="uploadHeader"
              action="/acb/2.0/parkFiling/uploadImage"
              :data="uploadData4"
              list-type="picture-card"
              :class="{ hide: hideUploadEdit4 }"
            >
              <i v-show="fileList.length < 1" class="el-icon-plus" />
            </el-upload>
          </div>
          <div class="grid-content bg-purple">
            <p>其他</p>
            <el-upload
              :on-remove="handleRemove5"
              :on-exceed="handleExceed"
              :before-upload="beforeUpload"
              :on-success="handleSuccess5"
              :on-change="handleEditChange5"
              :limit="10"
              :file-list="fileList5"
              name="image"
              :headers="uploadHeader"
              action="/acb/2.0/parkFiling/uploadImage"
              :data="uploadData5"
              list-type="picture-card"
              :class="{ hide: hideUploadEdit5 }"
            >
              <i v-show="fileList.length < 1" class="el-icon-plus" />
            </el-upload>
          </div>
        </el-collapse-item>
      </el-collapse>
      <upload-file
        ref="upload"
        :uploadData="uploadDataFile"
        @success="success"
        :uploadUrl="uploadUrl"
        :modelUrl="modelUrl"
      ></upload-file>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import infoTable from "@/components/infoTable/infoTable";
import uploadFile from "@/components/uploadFile/";
//  import { dateFormat } from "@/common/js/public.js";
export default {
  name: "recordParkAddBA",
  components: {
    infoTable,
    uploadFile,
  },
  props: {},
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      setList: [
        { label: "男", value: 1 },
        { label: "女", value: 2 },
      ],
      statusList: [
        { label: this.$t("list.On_the_job"), value: 1 },
        { label: this.$t("list.quit"), value: 2 },
      ],
      cityName: "北京",
      areaName: "",
      streetName: "",
      province: "220000",
      hideUploadEdit: false,
      hideUploadEdit1: false,
      hideUploadEdit2: false,
      hideUploadEdit3: false,
      hideUploadEdit4: false,
      hideUploadEdit5: false,
      uploadDataFile: {},
      uploadData: {
        parkFilingId: this.$route.query.parkFilingId,
        type: 10,
      },
      uploadData1: {
        parkFilingId: this.$route.query.parkFilingId,
        type: 20,
      },
      uploadData2: {
        parkFilingId: this.$route.query.parkFilingId,
        type: 30,
      },
      uploadData3: {
        parkFilingId: this.$route.query.parkFilingId,
        type: 40,
      },
      uploadData4: {
        parkFilingId: this.$route.query.parkFilingId,
        type: 50,
      },
      uploadData5: {
        parkFilingId: this.$route.query.parkFilingId,
        type: 60,
      },
      uploadUrl: "/acb/2.0/parkFilingUser/import",
      modelUrl: "/acb/2.0/parkFilingUser/downloadExcelTemplate",
      activeNames: ["1", "2", "3", "4", "5", "6", "7"],
      uploadHeader: {
        "Session-Id": sessionStorage.token,
      },
      isEdit: false,
      parkFilingTypeList: [], // 车场类型
      parkFilingPosition: [], // 环路位置
      facilitiesLandList: [], // 停车设施用地
      parkStatusList: [], // 停车场状态
      propertyRightsList: [], // 产权性质
      policeIdList: [], // 所属派出所
      trafficPoliceBranchIdList: [], // 所属交警分局
      businessCenterIdList: [], // 热门商圈
      parkIdList: [], // 平台车场
      companyList: [], // 经营企业
      parkingMethodList: [], // 停车方式
      occupationTypeList: [], // 占道类型
      fileList: [],
      fileList1: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      fileList5: [],
      fileValidate1: true,
      formData: {
        occupationType: "",
        parkingMethod: 1,
        parkFilingName: "",
        parkFilingCode: "",
        parkFilingType: "",
        city1: "",
        area1: "",
        street1: "",
        policeId: "",
        businessCenterId: "",
        trafficPoliceBranchId: "",
        address: "",
        parkFilingArea: "",
        fourToRange: "",
        position: "",
        facilitiesLand: "",
        parkId: "",
        parkStatus: "",
        personInCharge: "",
        personInChargePhone: "",
        telephone: "",
        totalBerthNumber: "",
        dedicatedBerthNumber: "",
        operationalBerthNumber: "",
        noOperationalBerthNumber: "",
        planningBerthNumber: "",
        sharedBerthNumber: "",
        longLeaseBerthNumber: "",
        elecVehiclesBerthNumber: "",
        barrierFreeBerthNumber: "",
        largeCarBerthNumber: "",
        littleCarBerthNumber: "",
        groundBerthNumber: "",
        undergroundBerthNumber: "",
        floorTypeBerthNumber: "",
        mechanicalLiftBerthNumber: "",
        groundMechanicalBerthNumber: "",
        underGroundMechanicalBerthNumber: "",
        walkingStereoBerthNumber: "",
        airDefenseBerthNumber: "",
        entryExitNumber: "",
        entryNumber: "",
        exitNumber: "",
        businessTimeBegin: "",
        businessTimeEnd: "",
        firstHourPrice: "",
        nofirstHourPrice: "",
        feeDesc: "",
        employeeNum: "",
        operationId: "",
        propertyRights: "",
      },
      cityList: [],
      areaList: [],
      streetList: [],
      businessTime: ["", ""],
      parkInfo: [
        {
          name: "停车场名称",
          // value: '新中关地下停车场',
          key: "parkFilingName",
          required: true,
        },
        {
          name: "停车场编号",
          key: "parkFilingCode",
          // value: 'DC-1929402',
          required: true,
        },
        {
          name: "停车场类型",
          // value: '公共停车场',
          key: "parkFilingType",
        },
        {
          name: "城市",
          // value: 'SJS31369H23AX',
          key: "city",
        },
        {
          name: "所属行政区",
          // value: '海淀区',
          key: "area",
          required: true,
        },
        {
          name: "所属街道",
          key: "street",
          // value: '中关村街道',
        },
        {
          name: "所属派出所",
          key: "policeId",
          // value: '中关村派出所',
        },
        {
          name: "所属商圈",
          key: "businessCenterId",
          // value: '中关村商圈',
        },
        {
          name: "所属交警分局",
          key: "trafficPoliceBranchId",
          // value: '海淀交警中关村分局',
        },
        {
          name: "详细地址",
          key: "address",
          // value: '海淀区海淀黄庄12号地下1层',
          required: true,
        },
        {
          name: "停车场面积(㎡）",
          key: "parkFilingArea",
          // value: '125',
        },
        {
          name: "停车场四至范围",
          key: "fourToRange",
          // value: '东至xxx路口，西至xxx大街，南至xxxx，北至xxxxx',
        },
        {
          name: "环路位置",
          key: "position",
          // value: '四环道五环之间',
        },
        {
          name: "停车设施用地",
          key: "facilitiesLand",
          // value: '用就用地',
        },
        {
          name: "占道类型",
          key: "occupationType",
          // value: '用就用地',
        },
        {
          name: "停车方式",
          key: "parkingMethod",
          // value: '用就用地',
        },
        {
          name: "停车场负责人",
          key: "personInCharge",
          // value: '张无忌',
        },
        {
          name: "负责人联系方式",
          key: "personInChargePhone",
          // value: '13420020001',
        },
        {
          name: "固定电话",
          key: "telephone",
          // value: '010-68281918',
        },
        {
          name: "停车场状态",
          key: "parkStatus",
          // value: '有效',
        },
        {
          name: "关联平台车场",
          key: "parkId",
          // required: true
          // value: '新中关地下停车场',
        },
      ],
      berthInfo: [
        {
          name: "总泊位数",
          // value: '150',
          key: "totalBerthNumber",
          required: true,
        },
        {
          name: "专用泊位数",
          // value: '0',
          key: "dedicatedBerthNumber",
        },
        {
          name: "经营性泊位数",
          // value: '100',
          key: "operationalBerthNumber",
          required: true,
        },
        {
          name: "非经营性泊位数",
          // value: '50',
          key: "noOperationalBerthNumber",
        },
        {
          name: "规划泊位数",
          // value: '150',
          key: "planningBerthNumber",
        },
        {
          name: "共享泊位数",
          // value: '10',
          key: "sharedBerthNumber",
        },
        {
          name: "长租泊位数",
          // value: '10',
          key: "longLeaseBerthNumber",
        },
        {
          name: "电动汽车专用泊位数",
          // value: '10',
          key: "elecVehiclesBerthNumber",
        },
        {
          name: "无障碍泊位数",
          // value: '10',
          key: "barrierFreeBerthNumber",
        },
        {
          name: "大型车泊位数",
          // value: '10',
          key: "largeCarBerthNumber",
        },
        {
          name: "小型车泊位数",
          // value: '140',
          key: "littleCarBerthNumber",
        },
        {
          name: "地面泊位数",
          // value: '50',
          key: "groundBerthNumber",
        },
        {
          name: "地下泊位数",
          // value: '100',
          key: "undergroundBerthNumber",
        },
        {
          name: "楼层式泊位数",
          // value: '0',
          key: "floorTypeBerthNumber",
        },
        {
          name: "机械提升式泊位数",
          // value: '0',
          key: "mechanicalLiftBerthNumber",
        },
        {
          name: "地上机械式泊位数",
          // value: '0',
          key: "groundMechanicalBerthNumber",
        },
        {
          name: "地下机械式泊位数",
          // value: '0',
          key: "underGroundMechanicalBerthNumber",
        },
        {
          name: "自走式立体停车泊位数",
          // value: '0',
          key: "walkingStereoBerthNumber",
        },
        {
          name: "人防车位数",
          // value: '0',
          key: "airDefenseBerthNumber",
        },
      ],
      outInInfo: [
        {
          name: "出入口总数",
          // value: '4',
          key: "entryExitNumber",
        },
        {
          name: "入口数量",
          // value: '2',
          key: "entryNumber",
        },
        {
          name: "出口数量",
          // value: '2',
          key: "exitNumber",
        },
      ],
      inInfo: [
        {
          name: "入口1编号",
          // value: 'R0101'
          key: "entry1Code",
        },
        {
          name: "入口1经度",
          // value: '108.123456'
          key: "entry1Longitude",
        },
        {
          name: "入口1纬度",
          // value: '40.123456'
          key: "entry1Latitude",
        },
        {
          name: "入口1状态",
          // value: '开放'
          key: "entry1type",
        },
        {
          name: "入口2编号",
          // value: 'R0102'
          key: "entry2Code",
        },
        {
          name: "入口2经度",
          // value: '108.123456'
          key: "entry2Longitude",
        },
        {
          name: "入口2纬度",
          // value: '40.123456'
          key: "entry2Latitude",
        },
        {
          name: "入口2状态",
          // value: '关闭'
          key: "entry2type",
        },
      ],
      outInfo: [
        {
          name: "出口1编号",
          // value: 'R0101'
          key: "out1Code",
        },
        {
          name: "出口1经度",
          // value: '108.123456'
          key: "out1Longitude",
        },
        {
          name: "出口1纬度",
          // value: '40.123456'
          key: "out1Latitude",
        },
        {
          name: "出口1状态",
          // value: '开放'
          key: "out1type",
        },
        {
          name: "出口2编号",
          // value: 'R0102'
          key: "out2Code",
        },
        {
          name: "出口2经度",
          // value: '108.123456'
          key: "out2Longitude",
        },
        {
          name: "出口2纬度",
          // value: '40.123456'
          key: "out2Latitude",
        },
        {
          name: "出口2状态",
          // value: '关闭'
          key: "out2type",
        },
      ],
      chargeInfo: [
        {
          name: "营业时间",
          // value: '00:00~00:00'
          key: "businessTime",
        },
        {
          name: "首小时价格",
          // value: '5',
          key: "firstHourPrice",
        },
        {
          name: "非首小时每小时单价",
          // value: '10'
          key: "nofirstHourPrice",
        },
      ],
      ruleInfo: [
        {
          name: "计费规则描述",
          required: true,
          key: "rule",
        },
      ],
      employeeInfo: [
        {
          name: "员工总数",
          key: "employeeNum",
        },
      ],
      tableData: [],
      tableCols: [
        {
          prop: "userCode",
          label: this.$t("list.Employee_ID"),
          width: "",
        },
        {
          prop: "userName",
          label: this.$t("list.pda_Manager_Name"),
          width: "",
        },
        {
          prop: "gender",
          label: "性别",
          width: "",
          formatter: (row) => {
            return row.gender == 1 ? "男" : "女";
          },
        },
        {
          prop: "phone",
          label: "联系方式",
          width: "",
        },
        {
          prop: "idCard",
          label: "身份证",
          width: "",
        },
        {
          prop: "domicile",
          label: "户籍",
          width: "",
        },
        {
          prop: "status",
          label: "员工状态",
          width: "",
          formatter: (row) => {
            return row.status == 0 ? "离职" : "在职";
          },
        },
      ],
      propertyInfo: [
        {
          name: "经营企业",
          // value: '智慧互通停车管理有限公司',
          key: "operationId",
          required: true,
        },
        {
          name: "产权性质",
          // value: '自有'
          key: "propertyRights",
        },
        {
          name: "产权方",
          // value: ''
          key: "property",
        },
        {
          name: "停车场租赁有效期",
          // value: '2020年10月20日'
          key: "leaseValidity",
        },
      ],
      entryTable: [
        {
          //          entryType: '1',
          //          entryCode: '1001',
          //          entryLongitude: '142.2121',
          //          entryLatitude: '42.1231',
          entryStatus: 1,
          entryType: "1",
          entryCode: "",
          entryLongitude: "",
          entryLatitude: "",
        },
      ],
      exitTable: [
        {
          entryType: "2",
          entryCode: "",
          entryLongitude: "",
          entryLatitude: "",
          entryStatus: 1,
        },
      ],
      entryStatusList: [
        {
          label: "关闭",
          value: 2,
        },
        {
          label: "开放",
          value: 1,
        },
      ],
      dialogImageUrl: "",
      dialogVisible: false,
      successImage: [],
    };
  },
  watch: {
    tableData: {
      handler() {
        this.formData.employeeNum = this.tableData.length || "";
      },
    },
  },
  created() {},
  mounted() {
    this.getBusinessAll();
    this.getAddOption();
    this.getParkFiling();
    this.getTrafficPolic();
    this.getParkIdList();
    this.getCompanyList();
    this.getCity();
    if (this.$route.query.parkFilingId) {
      this.isEdit = true;
      this.getDetailList();
    }
  },
  methods: {
    openTimeChange(value) {
      this.formData.businessTimeBegin = value[0];
      this.formData.businessTimeEnd = value[1];
    },
    // 删除图片
    handleRemove(file, fileList) {
      this.fileList = fileList;
      this.hideUploadEdit = fileList.length >= 1;
      this.setImages();
    },
    // 删除图片
    handleRemove1(file, fileList) {
      this.fileList1 = fileList;
      this.hideUploadEdit1 = fileList.length >= 1;
    },
    // 删除图片
    handleRemove2(file, fileList) {
      this.fileList2 = fileList;
      this.hideUploadEdit2 = fileList.length >= 1;
    },
    // 删除图片
    handleRemove3(file, fileList) {
      this.fileList3 = fileList;
      this.hideUploadEdit3 = fileList.length >= 1;
    },
    // 删除图片
    handleRemove4(file, fileList) {
      this.fileList4 = fileList;
      this.hideUploadEdit4 = fileList.length >= 1;
    },
    // 删除图片
    handleRemove5(file, fileList) {
      this.fileList5 = fileList;
      this.hideUploadEdit5 = fileList.length >= 1;
    },
    // 超出限制提示
    handleExceed(files, fileList) {
      this.$message.warning("只能上传10张图");
    },
    handleEditChange(file, fileList) {
      if (!this.fileValidate1) {
        fileList.length = fileList.length - 1;
        this.fileList = fileList;
        return;
      }
      this.setImages();
      this.uploadData.parkFilingId = this.formData.parkFilingId;
      this.uploadData.type = 10 + (fileList.length - 1);
      this.hideUploadEdit = fileList.length >= 1;
    },
    handleEditChange1(file, fileList) {
      this.setImages();
      this.uploadData1.parkFilingId = this.formData.parkFilingId;
      this.uploadData1.type = 20 + (fileList.length - 1);
      this.hideUploadEdit1 = fileList.length >= 1;
    },
    handleEditChange2(file, fileList) {
      this.setImages();
      this.uploadData2.parkFilingId = this.formData.parkFilingId;
      this.uploadData2.type = 3 + "" + (fileList.length - 1);
      this.hideUploadEdit2 = fileList.length >= 1;
    },
    handleEditChange3(file, fileList) {
      this.setImages();
      this.uploadData3.parkFilingId = this.formData.parkFilingId;
      this.uploadData3.type = 4 + "" + (fileList.length - 1);
      this.hideUploadEdit3 = fileList.length >= 1;
    },
    handleEditChange4(file, fileList) {
      this.setImages();
      this.uploadData4.parkFilingId = this.formData.parkFilingId;
      this.uploadData4.type = 5 + "" + (fileList.length - 1);
      this.hideUploadEdit4 = fileList.length >= 1;
    },
    handleEditChange5(file, fileList) {
      this.setImages();
      this.uploadData5.parkFilingId = this.formData.parkFilingId;
      this.uploadData5.type = 6 + "" + (fileList.length - 1);
      this.hideUploadEdit5 = fileList.length >= 1;
    },
    // 同步上传的照片总数
    setImages() {
      this.successImage = [
        ...this.fileList,
        ...this.fileList1,
        ...this.fileList2,
        ...this.fileList3,
        ...this.fileList4,
        ...this.fileList5,
      ];
    },
    // 上传前的验证
    beforeUpload(file) {
      this.fileValidate(file);
    },
    // 文件类型验证
    fileValidate(file) {
      const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isJpg = suffix === "jpg";
      const isPng = suffix === "png";
      const isPdf = suffix === "pdf";
      if (!isJpg && !isPdf && !isPng) {
        this.fileValidate1 = false;
        this.$message.warning("图片格式错误，只支持jpg、png、pdf格式!");
        return false;
      } else {
        this.fileValidate1 = true;
      }
      return true;
    },
    // 上传成功回调
    handleSuccess(res, file, fileList) {
      this.fileList.push({
        type: 10 + fileList.length - 1,
        url: res.value,
      });
      // // this.fileList = [];
      // for (var i = 0; i < fileList.length; i++) {
      //   let obj = {}
      //   var obj1 = []
      //   obj.type = 10 + i
      //   obj.url = res.value
      //   obj1.push(obj)
      //   this.fileList.push(obj)
      // }
    },
    handleSuccess1(res, file, fileList) {
      this.fileList1.push({
        type: 20 + fileList.length - 1,
        url: res.value,
      });
      // this.fileList1 = [];
      // for (var i = 0; i < fileList.length; i++) {
      //   let obj = {}
      //   obj.type = 20 + i
      //   obj.url = res.value
      //   this.fileList1.push(obj)
      // }
    },
    handleSuccess2(res, file, fileList) {
      this.fileList2.push({
        type: 30 + fileList.length - 1,
        url: res.value,
      });
      // this.fileList2 = [];
      // for (var i = 0; i < fileList.length; i++) {
      //   let obj = {}
      //   obj.type = 30 + i
      //   obj.url = res.value
      //   this.fileList2.push(obj)
      // }
    },
    handleSuccess3(res, file, fileList) {
      this.fileList3.push({
        type: 40 + fileList.length - 1,
        url: res.value,
      });
      // this.fileList3 = [];
      // for (var i = 0; i < fileList.length; i++) {
      //   let obj = {}
      //   obj.type = 40 + i
      //   obj.url = res.value
      //   this.fileList3.push(obj)
      // }
    },
    handleSuccess4(res, file, fileList) {
      this.fileList4.push({
        type: 50 + fileList.length - 1,
        url: res.value,
      });
      // this.fileList4 = [];
      // for (var i = 0; i < fileList.length; i++) {
      //   let obj = {}
      //   obj.type = 50 + i
      //   obj.url = res.value
      //   this.fileList4.push(obj)
      // }
    },
    handleSuccess5(res, file, fileList) {
      this.fileList5.push({
        type: 60 + fileList.length - 1,
        url: res.value,
      });
      // this.fileList5 = [];
      // for (var i = 0; i < fileList4.length; i++) {
      //   let obj = {}
      //   obj.type = 60 + i
      //   obj.url = res.value
      //   this.fileList5.push(obj)
      // }
    },
    selectLogs(data) {},
    //      备案车场枚举
    getAddOption(key, dataValue) {
      this.$axios.get("/acb/2.0/parkFiling/getAddOption").then((res) => {
        if (res.state == 0) {
          this.parkFilingTypeList = res.value.parkFilingType; // 停车场类型
          this.parkFilingPosition = res.value.parkFilingPosition; // 环路位置
          this.facilitiesLandList = res.value.facilitiesLand; // 停车设施用地
          this.propertyRightsList = res.value.propertyRights; // 产权性质
          this.parkStatusList = res.value.parkFilingparkStatus; // 停车场状态
          this.formData.parkStatus = this.parkStatusList[0].code;
          // this.uploadData.parkFilingId =  res.value.id
          this.formData.parkFilingId = res.value.id;
          this.parkingMethodList = res.value.parkingMethod; // 停车方式
          if (this.parkingMethodList.length) {
            this.formData.parkingMethod = this.parkingMethodList[0].code;
          }
          this.occupationTypeList = res.value.occupationType; // 占道类型
        } else {
          this.$alert(res.desc);
        }
      });
    },
    //  所属派出所
    getParkFiling() {
      this.$axios
        .get("/acb/2.0/parkFilingDictionary/list", {
          data: {
            dictionaryType: 1,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.policeIdList = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    //  所属交警
    getTrafficPolic() {
      this.$axios
        .get("/acb/2.0/parkFilingDictionary/list", {
          data: {
            dictionaryType: 2,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.trafficPoliceBranchIdList = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    //  所属热门商圈
    getBusinessAll() {
      this.$axios.get("/acb/2.0/businessCenter/getAll").then((res) => {
        if (res.state == 0) {
          this.businessCenterIdList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 关联平台车场
    getParkIdList() {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 0,
            slaveRelations: "0,1,2",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkIdList = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    //  所属经营企业
    getCompanyList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.companyList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    //  提交审核/存为草稿
    getSubmit(n) {
      //        auditStatus 1是草稿， 2是审核
      if (this.isEdit) {
        this.formData.parkFilingId = this.$route.query.parkFilingId;
      }
      let {
        parkFilingName,
        parkFilingCode,
        city1,
        area1,
        street1,
        address,
        totalBerthNumber,
        operationalBerthNumber,
        feeDesc,
        operationId,
      } = this.formData;
      if (
        parkFilingName == "" ||
        parkFilingCode == "" ||
        city1 == "" ||
        area1 == "" ||
        street1 == "" ||
        address == "" ||
        totalBerthNumber == "" ||
        operationalBerthNumber == "" ||
        feeDesc == "" ||
        operationId == ""
      ) {
        this.$alert("请选择带星号的必选项", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      let cityc = this.formData.city1 + "-" + this.cityName;
      let citya = this.formData.area1 + "-" + this.areaName;
      let citys = this.formData.street1 + "-" + this.streetName;
      let url = this.isEdit
        ? "/acb/2.0/parkFiling/history/update"
        : "/acb/2.0/parkFiling/history/add";
      this.$axios
        .post(url, {
          data: {
            ...this.formData,
            city: cityc,
            area: citya,
            street: citys,
            parkFilingUserList: JSON.stringify(this.tableData),
            parkFilingEntryList: JSON.stringify(this.entryTable),
            parkFilingExitList: JSON.stringify(this.exitTable),
            picTypeStr: JSON.stringify(this.successImage),
            //                  picTypeStr: this.formData.images,
            auditStatus: n,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.policeIdList = res.value.list;
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.$router.go(-1);
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 详情
    getDetailList() {
      this.$axios
        .get("/acb/2.0/parkFiling/history/getById/" + this.$route.query.parkFilingId, {})
        .then((res) => {
          if (res.state == 0) {
            this.formData = res.value;
            let cityID = res.value.city ? res.value.city.split("-")[0] : "1101";
            this.cityName = res.value.city ? res.value.city.split("-")[1] : "北京";
            this.formData.city1 = Number(cityID);
            let areaInfo = require("./dataJson/areas.json");
            this.areaList = areaInfo.filter((res) => {
              return res.cityCode == cityID;
            });
            let areaID = res.value.area ? res.value.area.split("-")[0] : "110101";
            this.areaName = res.value.area ? res.value.area.split("-")[1] : "东城区";
            this.formData.area1 = areaID + "";
            let streetInfo = require("./dataJson/streets.json");
            this.streetList = streetInfo.filter((res) => {
              return res.areaCode == areaID;
            });
            let streetID = res.value.street ? res.value.street.split("-")[0] : "110101001";
            this.streetName = res.value.street ? res.value.street.split("-")[1] : "东城区";
            this.$set(this.formData, "street1", streetID);
            this.formData.parkingMethod = res.value.parkingMethod
              ? res.value.parkingMethod.toString()
              : "1";
            this.tableData = res.value.parkFilingUserList || [];
            this.businessTime =
              res.value.businessTimeBegin && res.value.businessTimeEnd
                ? [res.value.businessTimeBegin + ":00", res.value.businessTimeEnd + ":00"]
                : ["", ""];
            let images = res.value.images;
            for (var i = 0; i < images.length; i++) {
              if (Number(images[i].type) >= 10 && Number(images[i].type) <= 19) {
                this.fileList.push(images[i]);
              } else if (Number(images[i].type) >= 20 && Number(images[i].type) <= 29) {
                this.fileList1.push(images[i]);
              } else if (images[i].type >= 30 && images[i].type <= 39) {
                this.fileList2.push(images[i]);
              } else if (images[i].type >= 40 && images[i].type <= 49) {
                this.fileList3.push(images[i]);
              } else if (images[i].type >= 50 && images[i].type <= 59) {
                this.fileList4.push(images[i]);
              } else if (images[i].type >= 60 && images[i].type <= 69) {
                this.fileList5.push(images[i]);
              }
            }
            this.successImage = [
              ...this.fileList,
              ...this.fileList1,
              ...this.fileList2,
              ...this.fileList3,
              ...this.fileList4,
              ...this.fileList5,
            ];
            this.entryTable = res.value.parkFilingEntryList || [];
            this.exitTable = res.value.parkFilingExitList || [];
          } else {
            this.$alert(res.desc);
          }
        });
    },
    success(res) {
      this.tableData = this.tableData.concat(res.value);
      var obj = {};
      let that = this;
      let arr = this.tableData.reduce(function (item, next) {
        obj[next.userCode] ? "" : (obj[next.userCode] = true && item.push(next));
        return item;
      }, []);
      this.tableData = arr;
    },
    allAn() {
      this.activeNames = ["1", "2", "3", "4", "5", "6", "7"];
    },
    packUp() {
      this.activeNames = [];
    },
    handleEntry() {
      this.entryTable.push({
        entryType: "1",
        entryCode: "",
        entryLongitude: "",
        entryLatitude: "",
        entryStatus: 1,
      });
    },
    handleEntryDel(i) {
      this.entryTable.splice(i, 1);
    },
    handleExit() {
      this.exitTable.push({
        entryType: "2",
        entryCode: "",
        entryLongitude: "",
        entryLatitude: "",
        entryStatus: 1,
      });
    },
    handleExitDel(i) {
      this.exitTable.splice(i, 1);
    },
    handleExport() {
      this.$refs.upload.clearFiles();
      this.$refs.upload.open();
    },
    handleAddTable() {
      this.tableData.push({
        userCode: "",
        userName: "",
        gender: "",
        phone: "",
        idCard: "",
        domicile: "",
        status: "",
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    getCity() {
      this.cityList = require("./dataJson/cities.json");
      this.formData.city1 = 1101;
      this.getArea();
    },
    handleCity(v) {
      this.formData.city1 = v;
      let cn = this.cityList.filter((res) => {
        return res.cityId == v;
      });
      this.cityName = cn[0].name;
      this.getArea();
    },
    getArea() {
      let areaInfo = require("./dataJson/areas.json");
      this.areaList = areaInfo.filter((res) => {
        return res.cityCode == this.formData.city1;
      });
      this.formData.area1 = this.areaList[0].code;
      this.areaName = this.areaList[0].name;
      this.getStreet();
    },
    handleArea(v) {
      this.formData.area1 = v;
      let cn = this.areaList.filter((res) => {
        return res.code == v;
      });
      this.areaName = cn[0].name;
      this.getStreet();
    },
    getStreet() {
      let streetInfo = require("./dataJson/streets.json");
      this.streetList = streetInfo.filter((res) => {
        return res.areaCode == this.formData.area1;
      });
      this.formData.street1 = this.streetList[0].code;
      this.streetName = this.streetList[0].name;
    },
    handleStreet(v) {
      this.formData.street1 = v;
      let cn = this.streetList.filter((res) => {
        return res.code == v;
      });
      this.streetName = cn[0].name;
    },
  },
};
</script>

<style scoped lang="stylus">
.detailsWrapper {
  position: relative;
  padding: 10px;
  background: #fff;

  >>>.el-upload-list__item.is-uploading {
    dispaly: none;
  }
  .el-date-editor>>> .el-range-input{
    width:112px;
  }
  >>>.colValue .el-select {
    width: 100% !important;

    .el-input .el-input__inner {
      height: 30px;
      line-height: 30px;
      border: 0px;
    }
  }

  >>>.colValue .el-input {
    .el-input__inner {
      height: 30px;
      line-height: 30px;
      border: 0px;
    }
  }

  >>>.colValue .el-date-editor {
    width: 100% !important;
  }

  .inTable {
    margin-top: 20px;
  }

  .outTable {
    margin-top: 20px;
  }

  .textArea>>>textarea {
    height: 150px !important;
  }

  >>>.el-table {
    .el-table__header {
      height: 30px;
      line-height: 30px;
    }

    .el-table__body {
      height: 30px;
      line-height: 30px;

      .cell {
        padding: 0px;

        .el-input__inner {
          border: 0px;
        }
      }
    }

    th, td {
      padding: 0px;
      border: 0px;
      border-right: 1px solid #EBEEF5;
      border-bottom: 1px solid #EBEEF5;
    }

    th {
      background: #f5f7fa;
    }
  }

  .grid-content {
    p {
      text-align: left;
    }

    img {
      margin-top: 10px;
      width: 100%;
    }
  }

  .btnWrapper {
    position: absolute;
    top: 10px;
    right: 50px;
  }

  .tableWrapper {
    position: relative;
    padding: 20px 0px;

    .btn {
      position: absolute;
      top: 10px;
      right: 30px;
    }

    .content {
      padding-top: 30px;

      .box_del {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      >>>.el-row {
        flex: 1;

        .grid-content {
          border: 1px solid #ebeef5;
          overflow: hidden;

          >.el-input {
            float: left;
            width: 65%;
            line-height: 30px;
            height: 30px;
            font-size: 14px;
            text-align: center;

            .el-input__inner {
              border: 0px;
            }
          }

          label {
            float: left;
            width: 35%;
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            background: #f5f7fa;
          }

          .el-select {
            float: left;
            width: 65%;
            line-height: 30px;
            height: 30px;
            font-size: 14px;
            text-align: center;

            .el-input .el-input__inner {
              border: 0px;
            }
          }
        }
      }

      .delbtn {
        margin-left: 10px;
      }
    }
  }

  .employ {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;

    .export {
      margin-left: 20px;
    }
  }

  .addBtn {
    width: 100%;
    text-align: right;
  }

  .upload {
    text-align: left;
  }
}
</style>
